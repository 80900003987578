import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Datenschutzerklärung`}</h2>
    <p>{`Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.`}</p>
    <h3>{`Cookies`}</h3>
    <p>{`Diese Webseite verwendet keine Cookies. Sie müssen daher auch keinen Cookie-Banner bestätigen.`}</p>
    <h3>{`Analyse`}</h3>
    <p>{`Diese Webseite verwendet keine Analyse-Werkzeuge.`}</p>
    <h3>{`Datenschutzerklärung Google Maps`}</h3>
    <p>{`Diese Webseite verwendet das Produkt Google Maps von Google Inc. Durch Nutzung dieser Webseite erklären Sie sich mit der Erfassung, Bearbeitung sowie Nutzung der automatisiert erhobenen Daten durch Google Inc, deren Vertreter sowie Dritter einverstanden. Die Nutzungsbedingungen von Google Maps finden Sie unter `}<a parentName="p" {...{
        "href": "https://www.google.com/intl/de_de/help/terms_maps.html"
      }}>{`"Nutzungsbedingungen von Google Maps"`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      